import React from "react";
import '../../styles/semantic.min.css';
import SEO from "../../components/seo/Seo";
import LayoutContainer from "../../container/LayoutContainer";
import PageHeader from "../../components/utils/PageHeader";
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import {Container, Icon} from "semantic-ui-react";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";
import layout from "../../templates/layout.module.css";

export default () => <LayoutContainer>
    <SEO title={'Abbruch Onlinespende'}/>
    <MobileTabletContainer>
            <MainNavigation/>
            <Container fluid>
                <Icon name={'user cancel'} size={'massive'} color={'black'}
                      style={{margin: '0 auto', display: 'block', paddingTop: '3rem'}}/>
                <PageHeader title={'Leider wurde Ihre Onlinespende abgebrochen.'}/>
            </Container>
            <Footer/>
    </MobileTabletContainer>
    <DesktopContainer>
        <div className={layout.pageWrapper}>
            <MainNavigation/>
            <Container fluid>
                <Icon name={'user cancel'} size={'massive'} color={'black'}
                      style={{margin: '0 auto', display: 'block', paddingTop: '3rem'}}/>
                <PageHeader title={'Leider wurde Ihre Onlinespende abgebrochen.'}/>
            </Container>
            <Footer/>
        </div>
    </DesktopContainer>


</LayoutContainer>